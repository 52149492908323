.agents-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    gap: 25px;
    justify-content: center;
}

@media (min-width: 1024px) {
    .agents-grid {
        justify-content: flex-start;
        flex-direction: row;
    }
}

@media (min-width: 768px) {
    .agents-grid {
        justify-content: center;
        flex-direction: row;
    }

    .agents-grid>div {
        flex: 1 1 1 calc(33% - 25px);
        /* 2 cards per row */
        max-width: calc(33% - 25px);
    }
}



.no-agents-message {
    color: white;
    font-size: 1.25rem;
    margin-top: 2rem;
    text-align: center;
}

@media (min-width: 220px) and (max-width: 550px) {
    .agents-grid {
        justify-content: center;
    }

    .agents-grid>div {
        flex: 1 1 calc(50% - 25px);
        /* 2 cards per row */
        max-width: calc(50% - 25px);
    }
}